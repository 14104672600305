<template>
  <div id="bannerContainer" @click="console.log('TODO link zum playstore')">
    <img id='banner' src="@/assets/banner.jpg" style="height: 100vh">
  </div>
</template>

<script>
export default {
  name: 'LandingPage'
}
</script>

<style scoped>
#bannerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

#banner {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  #banner {
    object-position: center top;
    height: 100vh;
    width: auto;
  }
}
</style>
