<template>
  <div id="Impressum">
    <h1>Impressum</h1>

    <p>Danny Weißhaupt
    Sonnenallee 153
      12059 Berlin</p>

    <p>Telefon: +49 15115533708
      E-Mail: transedev@gmail.com</p>

    <p>Verantwortlich für den Inhalt (gem. § 55 Abs. 2 RStV):
      Danny Weißhaupt, Sonnenallee 153 12059 Berlin</p>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Impressum',
}
</script>

<style scoped>
#Impressum{
  padding: 10px;
}
</style>
