<template>
  <div id="footer">
    <span v-for="link in links" :key="link" @click="scrollToSection(link)">{{ link }}</span>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',
  data() {
    return {
      links: ["Impressum", "Datenschutz"]
    }
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({behavior: 'smooth'});
      }
    }
  }
}
</script>

<style scoped>
#footer {
  z-index: 10;
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: #141413;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
