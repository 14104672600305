<template>
  <LandingPage/>
  <Datenschutz/>
  <Impressum/>
  <Footer/>
</template>

<script>
import LandingPage from './components/LandingPage.vue'
import Footer from "@/components/Footer";
import Datenschutz from "@/components/Datenschutz.vue";
import Impressum from "@/components/Impressum";

export default {
  name: 'App',
  components: {
    Impressum,
    Datenschutz,
    LandingPage,
    Footer
  }
}
</script>

<style>
#app{
  color: #f8f3b1;
}
</style>
