<template>
  <div id="Datenschutz">
    <h1>Datenschutzrichtlinie für die App "Steampirates"</h1>

    <p>Letzte Aktualisierung: 25. August 2023</p>

    <p>Vielen Dank für die Nutzung der App "Steampirates". Diese Datenschutzrichtlinie erläutert, wie wir mit den persönlichen Informationen umgehen, die beim Nutzen unserer App erfasst werden. Bitte lies diese Richtlinie sorgfältig durch, um zu verstehen, wie wir deine Daten schützen und nutzen.</p>

    <h2>1. Erfasste Informationen</h2>

    <p>Wir erfassen keinerlei persönliche Daten von den Nutzern unserer App. Wir verwenden keine Drittanbieterdienste zur Datenerfassung. Es sei jedoch darauf hingewiesen, dass die App "Steampirates" Daten im Local Storage deines Geräts speichern kann.</p>

    <h2>2. Lokaler Speicher (Local Storage)</h2>

    <p>Die App "Steampirates" speichert den aktuellen Spielstand ausschließlich im Local Storage deines Geräts. Diese Daten dienen lediglich dem Zweck, deinen Fortschritt im Spiel zu speichern, damit du dort weiterspielen kannst, wo du aufgehört hast. Es werden keine Daten an unsere Server oder andere externe Quellen übertragen.</p>

    <h2>3. Datensicherheit</h2>

    <p>Obwohl wir keine persönlichen Daten erfassen, unternehmen wir dennoch angemessene Schritte, um die Sicherheit der von dir in der App bereitgestellten Informationen zu gewährleisten. Wir verwenden aktuelle Sicherheitsmaßnahmen, um unbefugten Zugriff, Verlust oder Diebstahl von Daten zu verhindern.</p>

    <h2>4. Änderungen an der Datenschutzrichtlinie</h2>

    <p>Wir behalten uns das Recht vor, diese Datenschutzrichtlinie jederzeit zu aktualisieren oder zu ändern. Alle Änderungen werden auf dieser Seite veröffentlicht, und das Datum der letzten Aktualisierung wird angepasst. Wir empfehlen, diese Seite regelmäßig auf Änderungen zu überprüfen.</p>

    <h2>5. Kontaktinformationen</h2>

    <p>Falls du Fragen zu unserer Datenschutzrichtlinie oder zur App hast, kontaktiere uns bitte unter <a href="mailto:transedev@gmail.com">transedev@gmail.com</a>.</p>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Datenschutz',
}
</script>

<style scoped>
#Datenschutz{
  padding: 10px;
}
</style>
